import {
  CalendarMonth,
  HourglassFull,
  LocalFireDepartment,
  MenuBook,
  NotificationsActive,
  TrendingUp,
  Videocam
} from '@mui/icons-material'
import { DashboardLink } from '../_types/dashboard-link.type'

const DASHBOARD_QUICK_LINKS: DashboardLink[] = [
  {
    icon: <MenuBook />,
    title: 'Read the Latest Wrap',
    supportingTxt:
      'Read the most recent market wrap and get caught up with previous wraps',
    btnTxt: 'Read It',
    link: '/daily-wrap'
  },
  {
    icon: <LocalFireDepartment />,
    title: 'View Sector Heaters',
    supportingTxt:
      'See our expert picks in a sector we think is going nowhere but through the roof',
    btnTxt: 'View',
    link: '/sector-heaters'
  },
  {
    icon: <NotificationsActive />,
    title: 'Live Market Alerts/Updates',
    supportingTxt:
      'Get alerts live right from this page, or view previous alerts',
    btnTxt: 'View',
    link: '/alerts'
  },
  {
    icon: <TrendingUp />,
    title: 'Swing Trades',
    supportingTxt:
      "View the swing trade portfolio from the last wrap",
    btnTxt: 'View',
    link: '/swing'
  },
  {
    icon: <CalendarMonth />,
    title: 'View Events',
    supportingTxt: 'See upcoming events in the Carnivore World',
    btnTxt: 'View',
    link: '/calendar'
  },
  {
    icon: <HourglassFull />,
    title: 'Long Term Trades',
    supportingTxt: `Power stocks hand-selected by Trader Z for the purpose of long-term trading (months, weeks)`,
    btnTxt: 'View',
    link: '/buy-hold'
  }
]

export default DASHBOARD_QUICK_LINKS
