import { Box, Typography } from '@mui/material'
import { KnowledgePiece } from '../../../_types/knowledge.interface'
import { StyleHelper } from '../../style-helper'

export const SwingKnowledge: KnowledgePiece = {
    id: 'swing',
    title: 'Welcome to the Swing Trading Portfolio!',
    content: (
        <Box>
            <Typography variant='body1'>
                You asked for the swing portfolio on this site, and we delivered it. Here's how it works:
            </Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
                Live Price Updates:
            </Typography>
            <Typography variant='body1'>
                Stock prices in the portfolio are updated in real time, so you can stay informed about the latest market movements.
            </Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='subtitle1' fontWeight={'bold'} color={'primary'}>
                Position Updates After the Wrap:
            </Typography>

            <Typography variant='body1'>
                To maintain accuracy, portfolio positions are updated only after the daily wrap is published. This ensures you always have the most reliable data.
            </Typography>
        </Box>
    )
}
