import { useEffect, useState } from 'react'
import PageProps from '../_types/page-props.interface'
import { useError } from '../context/error'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { LongTermService } from '../services/buyhold.service'
import { LongTerm, LongTermStock } from '../_types/longterm.type'
import { Formatters } from '../_helpers/formatter.helper'
import {
  Box,
  Container,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  styled,
  TableCell,
  TableBody,
  Tooltip,
  Link,
  Grid,
  Chip,
  Tabs,
  Tab
} from '@mui/material'
import KnowledgeBtn from '../components/controls/knowledge-btn'
import { StyleHelper } from '../components/style-helper'
import LoadingTable from '../components/loading/table.loading'
import { Cancel, Chat, CheckCircle, CloseRounded, Notes, Restore } from '@mui/icons-material'
import StockCommentary from '../components/stock-commentary'
import TickerLink from '../components/ticker-link'

export default function BuyHoldPage(props: PageProps) {
  useDocumentTitle(props.title)

  const { setError } = useError()

  const [loading, setLoading] = useState(true)

  const longTermService = new LongTermService(setError)
  const [longTerm, setLongTerm] = useState<LongTerm | null>(null)
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    _getBuyHold()
  }, [])
  const _getBuyHold = async () => {
    setLoading(true)
    const res = await longTermService.getCurrentBuyHold()
    setLongTerm(res.longTerm)
    setLoading(false)

  }

  const _calculatePercentageChange = (
    initialValue: number,
    finalValue: number
  ): string => {
    const change = finalValue - initialValue
    return Formatters.formatPercentage((change / initialValue) * 100)
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const _scrollToCommentary = () => {
    const scrollTo = document.getElementById('carnivore-commentary')
    if (scrollTo) {
      scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const _scrollToSold = () => {
    const scrollTo = document.getElementById('sold-longterm')
    if (scrollTo) {
      scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const _isStockInBuy = (stock: LongTermStock) => {

    const entryPX = stock.entryPX ?? 0
    const buyUpTo = entryPX * 1.25
    const stopLoss = entryPX * 0.75

    const currentPX = stock.currentPX ?? 0

    return currentPX > stopLoss && currentPX < buyUpTo

  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', pb: 4 }}>
      <Grid container>
        <Grid item xs={12} lg={10}>
          <Box display='flex' alignItems='center' gap={2}>
            <Typography variant='h4'>Long Term Trades</Typography>
            <KnowledgeBtn id='buy-hold' />
          </Box>
          <StyleHelper.SmallVerticalSpace />
          <Typography variant='body2'> The Long-term trads are Carnivore Trading's way of putting a handful of high-quality stock picks in your hands and, in turn, putting money in your pockets. Each of these power stocks has been hand-selected by Trader Z for the purpose of long-term trading (months, weeks). Throughout the year, we will continue to add and alert you of new stocks and any changes we might make! </Typography>
        </Grid>

      </Grid>

      <StyleHelper.VerticalSpace />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
          setActiveTab(newValue);
        }} aria-label="basic tabs example">
          <Tab label="Active Positions" />
          <Tab label="Closed Positions" />
          {/* <Tab label="Commentary" /> */}
        </Tabs>
      </Box>
      <StyleHelper.VerticalSpace />

      {loading && <LoadingTable rowCount={7} columnCount={9} />}
      {!loading && longTerm && activeTab === 0 && (
        <Box>


          <TableContainer component={Paper} sx={{ maxHeight: '800px', overflow: 'auto' }}>
            <Table aria-label='buy & hold table'>
              <TableHead
                sx={{
                  '& th': {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    position: 'sticky',  // Make the headers sticky
                    top: 0,              // Set it to stick at the top
                    zIndex: 20
                  }
                }}
              >
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell align='right'>Name</TableCell>
                  <TableCell align='right'>Entry Date</TableCell>
                  <TableCell align='right'>Entry PX</TableCell>
                  <TableCell align='right'>Current PX</TableCell>
                  <TableCell align='right'>Gain/Loss</TableCell>
                  <TableCell align='right'>Stop Loss</TableCell>
                  <TableCell align='right'>Buy Up To</TableCell>
                  <TableCell align='right'>Carnivore Buy Zone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {longTerm.stocks.filter(stock => !stock.isSold).map(stock => (
                  <StyledTableRow
                    key={stock.symbol}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope='row' align='right'>
                      <TickerLink display='link' ticker={stock.symbol} />
                    </TableCell>
                    <TableCell align='right'>{stock.name}</TableCell>
                    <TableCell align='right'>
                      {new Date(stock.entryDate).toLocaleDateString('en-US')}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney(stock.entryPX)}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney(stock.currentPX ?? 0)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        color:
                          stock.entryPX < (stock.currentPX ?? 0)
                            ? 'success.main'
                            : 'error.main'
                      }}
                    >
                      {_calculatePercentageChange(
                        stock.entryPX,
                        stock.currentPX ?? 0
                      )}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney((stock.entryPX ?? 0) * 0.75)}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney((stock.entryPX ?? 0) * 1.25)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        color:
                          _isStockInBuy(stock)

                            ? 'success.main'
                            : 'error.main'
                      }}
                    >
                      {_isStockInBuy(stock)
                        ? (
                          <CheckCircle color='success' />
                        ) : (
                          <Cancel color='error' />
                        )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <StyleHelper.DoubleVerticalSpace />
      {!loading && longTerm && activeTab === 1 && (
        <Box id="sold-longterm">

          <TableContainer component={Paper} sx={{ maxHeight: '800px', overflow: 'auto' }}>
            <Table aria-label='buy & hold table'>
              <TableHead
                sx={{
                  '& th': {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    position: 'sticky',  // Make the headers sticky
                    top: 0,              // Set it to stick at the top
                    zIndex: 20
                  }
                }}
              >
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell align='right'>Name</TableCell>
                  <TableCell align='right'>Entry Date</TableCell>
                  <TableCell align='right'>Close Date</TableCell>
                  <TableCell align='right'>Entry PX</TableCell>
                  <TableCell align='right'>Sold PX</TableCell>
                  <TableCell align='right'>Gain/Loss</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {longTerm.stocks.filter(stock => stock.isSold === true)?.sort((a, b) => new Date(a.soldDate ?? "").getTime() - new Date(b.soldDate ?? "").getTime()).map(stock => (
                  <StyledTableRow
                    key={stock.symbol}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope='row' align='right'>
                      <TickerLink display='link' ticker={stock.symbol} />
                    </TableCell>
                    <TableCell align='right'>{stock.name}</TableCell>
                    <TableCell align='right'>
                      {new Date(stock.entryDate).toLocaleDateString('en-US')}
                    </TableCell>
                    <TableCell align='right'>
                      {new Date(stock.soldDate ?? "").toLocaleDateString('en-US')}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney(stock.entryPX)}
                    </TableCell>
                    <TableCell align='right'>
                      ${Formatters.formatMoney(stock.soldAt ?? 0)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        color:
                          stock.entryPX < (stock.soldAt ?? 0)
                            ? 'success.main'
                            : 'error.main'
                      }}
                    >
                      {_calculatePercentageChange(
                        stock.entryPX,
                        stock.soldAt ?? 0
                      )}
                    </TableCell>


                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {!loading && longTerm && activeTab === 2 && (
        <Box id="carnivore-commentary">

          <Typography variant='body2' gutterBottom>
            Click to expand and read Trader Z's Commentary on selected Buy &
            Hold Positions
          </Typography>
          {longTerm?.stocks
            .filter(s => s.commentary && s.commentary !== '' && s.isSold !== true)?.length === 0 && <Typography variant='body2' fontWeight={'bold'} textAlign={'start'} fontStyle={'italic'}>There is no active commentary.</Typography>}
          {longTerm?.stocks
            .filter(s => s.commentary && s.commentary !== '' && s.isSold !== true)
            .map(stock => (
              <StockCommentary
                ticker={stock.symbol}
                key={stock.symbol}
                commentary={stock.commentary ?? ''}
                name={stock.name}
              />
            ))}
        </Box>
      )}
    </Container>
  )
}