import { Box, Typography } from '@mui/material'
import { KnowledgePiece } from '../../../_types/knowledge.interface'
import { StyleHelper } from '../../style-helper'

export const SoundKnowledge: KnowledgePiece = {
    id: 'sound',
    title: 'Notification Sounds',
    content: (
        <Box>
            <Typography variant='body1'>
                To ensure a secure and seamless browsing experience, modern browsers require user interaction with a page before allowing sound to play automatically. If you bookmark this page and navigate directly to it, you'll need to click the muted button to enable sound notifications. This is a built-in browser policy designed to protect users from unexpected audio playback, and unfortunately, we're unable to bypass it. </Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='body1'>
                However, if you first land on your dashboard or any other page and then navigate here, your initial click will count as the interaction needed to enable audio. Once you've interacted with the page, we'll remember your preference so you won't need to toggle the muted button again.</Typography>
            <StyleHelper.VerticalSpace />
            <Typography variant='body1'>For the best experience and to stay on top of alerts, we highly recommend enabling browser notifications. They're a reliable and immediate way to receive updates, and they don't require additional interactions to work. With notifications enabled, you'll never miss an important alert!</Typography>
        </Box>
    )
}
