import { useEffect, useState } from 'react'
import PageProps from '../../_types/page-props.interface'
import { useError } from '../../context/error'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { CarnivoreEvent } from '../../_types/carnivore-event.type'
import { EventService } from '../../services/events.service'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import EventCard from '../../components/eventcard'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { StyleHelper } from '../../components/style-helper'
import { Close, Filter, FilterAlt } from '@mui/icons-material'
import EventCardLoading from '../../components/loading/event-card.loading'
import NoResults from '../../components/no-results'
import LoadingButton from '../../components/controls/loading-btn'
import EventModal from '../../components/modals/event-modal'
import useQueryParamsV2 from '../../hooks/userQueryParamsV2'
import { useNavigate } from 'react-router-dom'

interface EventsPageQueryParms {
  startDate?: string
  endDate?: string
  eventType?: string
}
export default function EventsPage(props: PageProps) {
  useDocumentTitle(props.title)
  const [queryParams, setQueryParams] = useQueryParamsV2<EventsPageQueryParms>()

  const { setError } = useError()
  const [page, setPage] = useState(1)
  const [refreshing, setRefreshing] = useState(true)
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<CarnivoreEvent[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState<string>(
    queryParams.startDate ?? formatDate(new Date())
  )
  const [endDate, setEndDate] = useState<string>(
    queryParams.endDate ?? formatDate(addMonths(new Date(), 3))
  )
  const theme = useTheme()

  const [eventType, setEventType] = useState(queryParams.eventType ?? '')

  const eventService = new EventService(setError)
  const [currentEvent, setCurrentEvent] = useState<CarnivoreEvent | null>(null)

  useEffect(() => {
    _fetchEvents(1, startDate, endDate, eventType)
  }, [])

  function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  function addMonths(date: Date, months: number): Date {
    const result = new Date(date)
    result.setMonth(result.getMonth() + months)
    return result
  }

  const _setToMidnight = (d: Date) => {
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
  }

  const _fetchEvents = async (
    p: number,
    startD: string,
    endD: string,
    eventT: string
  ) => {
    setLoading(true)
    setPage(p)
    const start = new Date(startD)
    const end = new Date(endD)
    _setToMidnight(start)
    _setToMidnight(end)
    setQueryParams({
      endDate: endD,
      startDate: startD,
      eventType: eventT
    })
    const results = await eventService.searchEvents(
      page,
      start.toISOString(),
      end.toISOString(),
      eventT
    )
    if (results.docs) {
      setEvents(results.docs)
      setHasNextPage(results.hasNextPage)
      setHasPrevPage(results.hasPrevPage)
      setTotalPages(results.totalPages)
    }
    setRefreshing(false)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const _clearSearch = () => {
    const s = new Date()
    setStartDate(formatDate(s))
    setEndDate(formatDate(addMonths(new Date(), 2)))
    setEventType('')
    _fetchEvents(1, formatDate(s), formatDate(addMonths(new Date(), 2)), '')
  }

  const _viewPastCalls = async () => {
    const start = new Date()
    const end = new Date()
    end.setDate(end.getDate() + 2)

    start.setMonth(start.getMonth() - 1)
    setStartDate(formatDate(start))
    setEndDate(formatDate(end))
    setEventType('member-call')

    _fetchEvents(1, formatDate(start), formatDate(end), 'member-call')
  }

  const _todaysEvents = async () => {
    const start = new Date()
    const end = new Date()
    end.setDate(end.getDate() + 1)

    start.setDate(start.getDate() - 1)
    setStartDate(formatDate(start))
    setEndDate(formatDate(end))


    _fetchEvents(1, formatDate(start), formatDate(end), '')
  }

  return (
    <Container>
      <Paper
        sx={{
          border: `1px solid ${theme.palette.divider}` // Automatically adjusts for theme
          // Additional styles
        }}
      >
        <Box p={2}>
          <Typography variant='subtitle1' fontWeight={'bold'}>
            Filters
          </Typography>
          <StyleHelper.VerticalSpace />
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <TextField
                label='Start Date'
                type='date'
                fullWidth
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <TextField
                fullWidth
                label='End Date'
                type='date'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <FormControl fullWidth>
                <InputLabel id='event-type-label'>Event Type</InputLabel>
                <Select
                  labelId='event-type-label'
                  id='event-type-select'
                  value={eventType}
                  label='Event Type'
                  onChange={e => setEventType(e.target.value)}
                >
                  <MenuItem value=''>All Events</MenuItem>

                  <MenuItem value='general'>General</MenuItem>
                  <MenuItem value='news'>News</MenuItem>
                  <MenuItem value='member-call'>Member Calls</MenuItem>
                  <MenuItem value='academy'>Academy</MenuItem>
                  <MenuItem value='livestream'>Livestream</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <LoadingButton
                variant='contained'
                endIcon={<FilterAlt />}
                onClick={() => _fetchEvents(1, startDate, endDate, eventType)}
                loading={loading}
                disabled={loading}
              >
                Apply Filter
              </LoadingButton>
              <IconButton onClick={_clearSearch} disabled={loading}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <StyleHelper.VerticalSpace />
          <Box display={'flex'}>
            <Chip label='Recent Calls' onClick={_viewPastCalls} />
            <StyleHelper.SmallHorizontalSpace />
            <Chip label="Today's Events" onClick={_todaysEvents} />
          </Box>
        </Box>
      </Paper>
      <StyleHelper.DoubleVerticalSpace />
      <Grid container spacing={2}></Grid>
      <Grid container flexWrap={'wrap'} spacing={2}>
        {/* <Grid item xs={12} lg={4}>
          <Paper sx={{ height: '100%' }}>
            <Box p={1} height={'100%'}>
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                events={events?.map(e => ({
                  title: e.title,
                  start: e.startTime,
                  end: e.endTime
                }))}
              />
            </Box>
          </Paper>
        </Grid> */}
        {loading &&
          Array.from(new Array(8)).map((_, index) => (
            <Grid item lg={4} xs={12}>
              <EventCardLoading key={index} />
            </Grid>
          ))}
        {!loading &&
          events?.map(e => (
            <Grid item lg={4} xs={12} key={e.id}>
              <EventCard
                event={e}
                openEventModal={() =>
                  navigate('/calendar/view', { state: { event: e } })
                }
              />
            </Grid>
          ))}
      </Grid>
      {!loading && events.length === 0 && <NoResults />}

      {currentEvent && (
        <EventModal
          open={currentEvent !== null}
          onClose={() => setCurrentEvent(null)}
          event={currentEvent}
          size='md'
        />
      )}
      <StyleHelper.DoubleVerticalSpace />
    </Container>
  )
}
