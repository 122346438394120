import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import LoginPage from './pages/login.page'
import SignupAccountPage from './pages/signup/signup-account.page'
import SignupEmailVerify from './pages/signup/signup-email-verify'
import Dashboard from './pages/dashboard.page'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import AlertsPage from './pages/alerts.page'
import HeatersPage from './pages/heaters.page'
import SignupPackagePage from './pages/signup/signup-package.page'
import SignupPaymentPage from './pages/signup/signup-payment.page'
import ProfilePage from './pages/profile.pages'
import SettingsPage from './pages/settings.page'
import StockPage from './pages/stock.pages'
import EventsPage from './pages/events/events.page'
import EventViewPage from './pages/events/event-view.pages'
import BuyHoldPage from './pages/buyhold.page'
import AcademyPage from './pages/academy.page'
import WrapPage from './pages/wrap/wraps.page'
import ViewWrapPage from './pages/wrap/view-wrap.page'
import VideosPage from './pages/video.page'
import LogoutPage from './pages/logout.page'
import AlertPage from './pages/alert.page'
import NoSubPage from './pages/no-sub.page'
import SignupSyncPage from './pages/signup/signup-sync.page'
import { useEffect } from 'react'
import ForgotFlowPage from './pages/forgot-flow/forgot-email'
import ForgotVerify from './pages/forgot-flow/forgot-verify'
import EducationPage from './pages/education.page'
import SwingTradePage from './pages/swing.page'

function RedirectToLogin() {
  useEffect(() => {
    window.location.href = '/login'
  }, [])
  return <></>
}
export default function CarnivoreRouter() {
  return (
    <Routes>
      <Route path='/no-sub' element={<NoSubPage title='No Subscription' />} />

      <Route path='/login' element={<LoginPage title='Login' />} />
      <Route path='/signup' element={<SignupAccountPage title='Signup' />} />
      <Route path='/profile' element={<ProfilePage title='Profile' />} />
      <Route path='/swing' element={<SwingTradePage title='Swing Trades' />} />

      <Route path='/longterm' element={<BuyHoldPage title='Long Term Trades' />} />
      <Route path='/academy' element={<AcademyPage title='Academy' />} />
      <Route path='/daily-wrap' element={<WrapPage title='Daily Wrap' />} />
      <Route
        path='/daily-wrap/view'
        element={<ViewWrapPage title='Daily Wrap' />}
      />
      <Route path='/videos' element={<VideosPage title='Content Library' />} />
      <Route path='/logout' element={<LogoutPage />} />

      <Route path='/calendar' element={<EventsPage title='Calendar' />} />
      <Route
        path='/calendar/view'
        element={<EventViewPage title='View Event' />}
      />

      <Route
        path='/signup/verify'
        element={<SignupEmailVerify title='Verify Email' />}
      />
      <Route path='/signup/sync' element={<SignupSyncPage title='Syncing' />} />
      <Route
        path='/signup/package'
        element={<SignupPackagePage title='Package' />}
      />
      <Route
        path='/signup/payment'
        element={<SignupPaymentPage title='Payment' />}
      />
      <Route path='/settings/*' element={<SettingsPage title='Settings' />} />
      <Route path='/settings' element={<SettingsPage title='Settings' />} />
      <Route
        path='/stock/:ticker'
        element={<StockPage title='Stock' layout='page' />}
      />
      <Route path='/education' element={<EducationPage title='Education' />} />

      <Route path='/dashboard' element={<Dashboard title='Dashboard' />} />
      <Route path='/alerts' element={<AlertsPage title='Alerts' />} />
      <Route path='/alerts/:id' element={<AlertPage title='Alert' />} />
      <Route
        path='/sector-heaters'
        element={<HeatersPage title='Sector Heaters' />}
      />

      <Route
        path='/forgot'
        element={<ForgotFlowPage title='Forgot Password' />}
      />
      <Route
        path='/forgot/reset'
        element={<ForgotVerify title='Reset Password' />}
      />

      <Route path='*' element={<RedirectToLogin />} />
    </Routes>
  )
}
