import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { CarnivoreEvent } from "../_types/carnivore-event.type";
import {

    CalendarMonth,
    Campaign,
    LiveTv,
    School,
    VideoCall,
    Videocam
} from '@mui/icons-material'
import { Formatters } from "../_helpers/formatter.helper";
import Grid2 from "@mui/material/Unstable_Grid2";
import { StyleHelper } from "./style-helper";
import { useNavigate } from "react-router-dom";
interface EventRowProps {
    event: CarnivoreEvent
}

export default function CarnivoreEventRow(props: EventRowProps) {
    const { event } = props
    const navigate = useNavigate()

    const _getIcon = () => {
        switch (event.eventType) {
            case 'news':
                return <Campaign fontSize={'large'} color="primary" />
            case 'member-call':
                return <VideoCall fontSize={'large'} color="primary" />
            case 'academy':
                return <School fontSize={'large'} color="primary" />
            case 'virtual-academy':
                return <Videocam fontSize={'large'} color="primary" />
            case 'livestream':
                return <LiveTv fontSize={'large'} color="primary" />
            default:
                return <CalendarMonth fontSize={'large'} color="primary" />
        }
    }

    return <Paper elevation={3} sx={{ margin: 1, cursor: 'pointer' }} onClick={() => {
        navigate(`/calendar/view?id=${event.id}`)
    }}>
        <Box p={2} display={'flex'}>
            <Box sx={{ flex: 1, alignSelf: 'center' }}>
                {_getIcon()}
            </Box>
            <Box sx={{ flex: 5 }} >
                <Typography variant="subtitle2" fontWeight={'bold'}>{event.title}</Typography>
                <StyleHelper.SmallVerticalSpace />
                <Grid2 container spacing={2}>
                    <Grid2 xs={6}>
                        <Typography variant="body2" >{new Date(event.startTime).toLocaleDateString('en-US', { dateStyle: 'medium' })}</Typography>

                    </Grid2>
                    <Grid2 xs={6}>
                        <Typography variant="body2" >{new Date(event.endTime).toLocaleDateString('en-US', { dateStyle: 'medium' })}</Typography>
                    </Grid2>
                </Grid2>

            </Box>


        </Box>
    </Paper>
}