// GlobalStateContext.js
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { CarnivoreUser } from '../_types/user.type'
import { CarnivoreSettings } from '../_types/carnivoresettings.type'
import { ViewedAlert } from '../_types/viewed-alert.type'
import { CarnivoreAlert } from '../_types/alert.type'
import { Banner } from '../_types/banner.type'

interface GlobalContextType {
  isSidebarOpen: boolean
  setIsSidebarOpen: (isSidebarOpen: boolean) => void

  isAuthed: boolean
  setIsAuthed: (isSidebarOpen: boolean) => void

  user?: CarnivoreUser
  setUser: (user: CarnivoreUser) => void

  settings?: CarnivoreSettings
  setSettings: (settings: CarnivoreSettings) => void

  showIntro: boolean
  setShowIntro: (showIntro: boolean) => void

  banner: Banner | null
  setBanner: (banner: Banner | null) => void

  viewedAlerts: ViewedAlert[]
  addToViewAlerts: (alerts: ViewedAlert[]) => void

  liveAlert: CarnivoreAlert | null
  setLiveAlert: (alert: CarnivoreAlert | null) => void


  isMuted: boolean
  setIsMuted: (isMuted: boolean) => void
}

const defaultState = {
  isSidebarOpen: false,
  setIsSidebarOpen: (value: boolean) => { },

  isAuthed: false,
  setIsAuthed: (value: boolean) => { },

  setUser: (user: CarnivoreUser) => { },

  setSettings: (settings: CarnivoreSettings) => { },

  showIntro: false,
  setShowIntro: (value: boolean) => { },

  banner: null,
  setBanner: (banner: Banner | null) => { },

  viewedAlerts: [],
  addToViewAlerts: (alerts: ViewedAlert[]) => { },

  liveAlert: null,
  setLiveAlert: (alert: CarnivoreAlert | null) => { },

  isMuted: false,
  setIsMuted: (value: boolean) => { },
}

const GlobalStateContext = createContext<GlobalContextType>(defaultState)
export const useGlobalState = () => useContext(GlobalStateContext)

interface GlobalProps {
  children: ReactNode
}

export const GlobalStateProvider = ({ children }: GlobalProps) => {
  const [isSidebarOpen, setSidebarOpen] = useState((localStorage.getItem('sidebar-open') ?? "open") === 'open')
  const [isAuthed, setIsAuthed] = useState(false)
  const [user, setUser] = useState<CarnivoreUser | undefined>()
  const [showIntro, setShowIntro] = useState<boolean>(false)
  const [banner, setBanner] = useState<Banner | null>(null)

  const [settings, setSettings] = useState<CarnivoreSettings | undefined>()
  const [viewedAlerts, setViewedAlerts] = useState<ViewedAlert[]>([])
  const [isMuted, setIsMuted] = useState(true)

  const [liveAlert, setLiveAlert] = useState<CarnivoreAlert | null>(null)
  const addToViewAlerts = (views: ViewedAlert[]) => {
    setViewedAlerts(prev => [...prev, ...views])
  }


  const setIsSidebarOpen = (value: boolean) => {
    localStorage.setItem('sidebar-open', value === true ? 'open' : 'closed')
    setSidebarOpen(value)
  }
  return (
    <GlobalStateContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
        setIsAuthed,
        isAuthed,
        user,
        setUser,
        settings,
        setSettings,
        showIntro,
        setShowIntro,
        viewedAlerts,
        addToViewAlerts,
        liveAlert,
        setLiveAlert,
        banner,
        setBanner,
        isMuted,
        setIsMuted
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
