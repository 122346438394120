import { Box, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import PageProps from "../_types/page-props.interface";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useError } from "../context/error";
import { useEffect, useState } from "react";
import { SwingService } from "../services/swing.service";
import { SwingPortfolio } from "../_types/swing-portfolio.type";
import { StyleHelper } from "../components/style-helper";
import TickerLink from "../components/ticker-link";
import { Formatters } from "../_helpers/formatter.helper";
import { Cancel, CheckCircle } from "@mui/icons-material";
import KnowledgeBtn from "../components/controls/knowledge-btn";

export default function SwingTradePage(props: PageProps) {
    useDocumentTitle(props.title)
    const { setError } = useError()

    const [loading, setLoading] = useState(true)
    const swingService = new SwingService(setError)
    const [portfolio, setPortfolio] = useState<SwingPortfolio | null>(null)

    useEffect(() => {
        _getPortfolio()
    }, [])
    const _getPortfolio = async () => {
        setLoading(true)
        const res = await swingService.getCurrentPortfolio()
        setPortfolio(updateStockPercentages(res.portfolio) ?? null)
        setLoading(false)
    }


    function updateStockPercentages(portfolio: SwingPortfolio): SwingPortfolio {
        // Calculate total value of all stocks
        let totalStocksValue = portfolio.stocks.reduce((total, stock) => {
            return total + stock.currentPX * stock.quantity;
        }, 0);
        totalStocksValue += portfolio.cashValue

        // Update each stock's percentOf field
        portfolio.stocks = portfolio.stocks.map(stock => {
            const stockValue = stock.currentPX * stock.quantity;
            const percentOf = totalStocksValue > 0 ? (stockValue / totalStocksValue) * 100 : 0;

            return {
                ...stock,
                percentOf: parseFloat(percentOf.toFixed(2)), // Format percentage to two decimal places
            };
        });

        return portfolio;
    }

    function getCashPercentage(portfolio: SwingPortfolio) {
        // Calculate total value of all stocks
        let totalStocksValue = portfolio.stocks.reduce((total, stock) => {
            return total + stock.currentPX * stock.quantity;
        }, 0);
        totalStocksValue += portfolio.cashValue



        return parseFloat((portfolio.cashValue / totalStocksValue * 100).toFixed(2));
    }


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }))

    // const _calculat = (
    //     initialValue: number,
    //     finalValue: number
    // ): string => {
    //     const change = finalValue - initialValue
    //     return Formatters.formatPercentage((change / initialValue) * 100)
    // }

    return <Container>
        <Box display='flex' alignItems='center' gap={2}>
            <Typography variant='h4'>Swing Trades</Typography>
            <KnowledgeBtn id='swing' />
        </Box>
        {portfolio && <Typography variant="caption">Last Updated: {new Date(portfolio?.createdAt ?? "").toLocaleString('en-US', { timeStyle: 'short', dateStyle: 'medium' })}</Typography>}
        <StyleHelper.VerticalSpace />
        {portfolio && <TableContainer component={Paper} sx={{ maxHeight: '800px', overflow: 'auto' }}>
            <Table aria-label='swing table'>
                <TableHead
                    sx={{
                        '& th': {
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            position: 'sticky',  // Make the headers sticky
                            top: 0,              // Set it to stick at the top
                            zIndex: 20
                        }
                    }}
                >
                    <TableRow>
                        <TableCell>Symbol</TableCell>
                        <TableCell align='right'>Name</TableCell>
                        <TableCell align='right'>Unit Cost</TableCell>
                        <TableCell align='right'>Quantity</TableCell>
                        <TableCell align='right'>Current PX</TableCell>
                        <TableCell align='right'>Market Value</TableCell>
                        <TableCell align='right'>% of Portfolio</TableCell>
                        <TableCell align='right'>New Today</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {portfolio.stocks.map(stock => (
                        <StyledTableRow
                            key={stock.symbol}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope='row' align='right'>
                                <TickerLink display='link' ticker={stock.symbol} />
                            </TableCell>
                            <TableCell align='right'>{stock.name}</TableCell>
                            <TableCell align='right'>
                                ${Formatters.formatMoney(stock.unitCost)}
                            </TableCell>
                            <TableCell align='right'>
                                {Formatters.formatCommas(stock.quantity)}
                            </TableCell>
                            <TableCell align='right'>
                                ${Formatters.formatMoney(stock.currentPX ?? 0)}
                            </TableCell>
                            <TableCell align='right'>
                                ${Formatters.formatMoney(((stock.currentPX ?? 0) * stock.quantity))}
                            </TableCell>
                            <TableCell
                                align='right'

                            >
                                {stock.percentOf}%
                            </TableCell>

                            <TableCell
                                align='right'
                                sx={{
                                    color:
                                        stock.isNew

                                            ? 'success.main'
                                            : 'error.main'
                                }}
                            >
                                {stock.isNew
                                    ? (
                                        <CheckCircle color='success' />
                                    ) : (
                                        <Cancel color='error' />
                                    )}
                            </TableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell scope='row' align='right'>
                        </TableCell>
                        <TableCell align='right'>Captools Cash Acct</TableCell>
                        <TableCell align='right'>
                            0
                        </TableCell>
                        <TableCell align='right'>
                            {Formatters.formatMoney(portfolio.cashValue)}
                        </TableCell>
                        <TableCell align='right'>

                        </TableCell>
                        <TableCell align='right'>
                        </TableCell>
                        <TableCell
                            align='right'

                        >
                            {getCashPercentage(portfolio)}%
                        </TableCell>

                        <TableCell
                            align='right'

                        >

                        </TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>}
    </Container>
}