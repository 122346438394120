import { ResponseHelper } from '../_helpers/response.helper'
import axiosInstance from './axios.service'

export class SwingService {
  private setError: (message: string | null) => void

  constructor (setError: (message: string | null) => void) {
    this.setError = setError
  }


  async getCurrentPortfolio () {
    return axiosInstance
      .get('/swing/current')
      .then(res => res.data.data)
      .catch(e => ResponseHelper.handleError(e, this.setError))
  }
}
